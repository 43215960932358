import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons"
import "../styles/Footer.styl"
import farkcorpLogo from "../images/fark-logo2.png"

export default function Footer() {
  const { t } = useTranslation()

  return (
    <footer>
      <div className="wrapper">
        <div className="company">
          <img
            src={farkcorpLogo}
            alt="farkcorp s.a. de s.v."
            className="company__image"
          />
          <h3 className="company__message">{t("footer.arm")}</h3>
        </div>
        <div className="contact-info">
          <div className="address contact-info__text-icon-container">
            <address>
              Farkcorp S.A. de C.V. Av Luis Lusati, Mexico Nuevo, 52966 Cd López
              Mateos, Méx.
            </address>
            <FontAwesomeIcon
              icon={faMapMarkedAlt}
              className="contact-info__icon"
            />
          </div>
          <div className="email contact-info__text-icon-container">
            contacto@farkcorp.com
            <FontAwesomeIcon icon={faEnvelope} className="contact-info__icon" />
          </div>
          <div className="phone contact-info__text-icon-container">
            55 53 41 42 42 - 55 53 41 42 32
            <FontAwesomeIcon icon={faPhoneAlt} className="contact-info__icon" />
          </div>
          <small className="notice-privacy">
            <Link to="/notice-of-privacy/" className="notice text--Blue">
              {t("notice.title")}
            </Link>
            <div className="reserved">Derechos Reservados</div>
            <div className="rights">Fark Corp ® 2020</div>
          </small>
        </div>
      </div>
    </footer>
  )
}
