// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notice-of-privacy-js": () => import("../src/pages/notice-of-privacy.js" /* webpackChunkName: "component---src-pages-notice-of-privacy-js" */),
  "component---src-pages-our-activity-js": () => import("../src/pages/our-activity.js" /* webpackChunkName: "component---src-pages-our-activity-js" */),
  "component---src-pages-who-we-are-js": () => import("../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

