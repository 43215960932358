/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { withTrans } from "../i18n/withTrans"

import Footer from "./Footer"
import "../styles/Layout.css"
import "../styles/Hero.styl"
import "../styles/Global.styl"

const Layout = ({ children, t, i18n, location }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  console.log(location.href)

  if (location.href !== "http://localhost:8000/contact/") {
    return (
      <>
        {children}
        <Footer />
      </>
    )
  } else {
    return <>{children}</>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
